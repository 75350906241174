import { useMemo } from 'react';
import useSWR from 'swr';
// utils
import axios, { endpoints, fetcher } from 'src/utils/network';

// ----------------------------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const BOOKING_TYPES = {
  LOCAL: 'local',
  AIRPORT: 'airport',
};

export function useGetAddresses(query, lat, lng) {
  const URL = query ? `${endpoints.places.map}?query=${query}&lat=${lat}&lng=${lng}` : null;

  const fetcherFn = URL ? () => fetcher(URL) : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherFn);

  const memoizedValue = useMemo(
    () => ({
      addresses: data?.places?.places || [],
      addressesLoading: isLoading,
      addressesError: error,
      addressesValidating: isValidating,
      addressesEmpty: !isLoading && !data?.places?.places?.length,
    }),
    [data?.places?.places, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetDistance(origin, destination) {
  const URL = origin && destination ? endpoints.places.distance : null;

  const fetcherFn = URL
    ? () =>
        axios
          .post(URL, {
            origin,
            destination,
          })
          .then((res) => res.data)
    : null;

  const { data, isLoading, error, isValidating } = useSWR(
    URL ? [URL, origin, destination] : null,
    fetcherFn,
    { revalidateOnFocus: false }
  );

  const memoizedValue = useMemo(
    () => ({
      distance: data?.data?.distance
        ? {
            distance: {
              text: data.data.distance.text,
              value: data.data.distance.value,
            },
            duration: {
              text: data.data.duration.text,
              value: data.data.duration.value,
            },
          }
        : null,
      distanceLoading: isLoading,
      distanceError: error,
      distanceValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetAirports() {
  const URL = endpoints.places.airport;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      airports: data?.places || [],
      airportsLoading: isLoading,
      airportsError: error,
      airportsValidating: isValidating,
      airportsEmpty: !isLoading && !data?.places?.length,
    }),
    [data?.places, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetCities() {
  const URL = endpoints.places.city;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      cities: data?.places || [],
      citiesLoading: isLoading,
      citiesError: error,
      citiesValidating: isValidating,
      citiesEmpty: !isLoading && !data?.places?.length,
    }),
    [data?.places, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetPackages(placeCode) {
  const URL = `${endpoints.cabs.packages}/${placeCode}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      packages: data?.data || [],
      packagesLoading: isLoading,
      packagesError: error,
      packagesValidating: isValidating,
      packagesEmpty: !isLoading && !data?.data?.length,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function createBooking(payload) {
  try {
    const URL = endpoints.cabs.booking;

    const response = await axios.post(URL, payload);

    return response.data;
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export function useGetBookings() {
  const URL = endpoints.cabs.booking;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      bookings: data?.data || [],
      bookingsLoading: isLoading,
      bookingsError: data?.error ?? error,
      bookingsValidating: isValidating,
      bookingsEmpty: !isLoading && !data?.data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetBookingDetail(id) {
  const URL = endpoints.cabs.bookingDetail(id);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      bookingDetail: data?.data,
      bookingDetailLoading: isLoading,
      bookingDetailError: error,
      bookingDetailValidating: isValidating,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
