// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, IconButton } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
//
import { useSnackbar } from 'notistack';
// hooks
import { useAuthContext } from 'src/auth/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { usePathname, useRouter } from 'src/routes/hooks';
// theme
import { bgBlur } from 'src/theme/css';
// components
import Iconify from 'src/components/iconify';
import Logo from 'src/components/logo';

//
import { HEADER } from '../config-layout';
//
import { AccountPopover, HeaderShadow } from '../_common';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();

  const { login, authenticated } = useAuthContext();

  const router = useRouter();

  const loading = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const pathname = usePathname();

  const isHome = pathname === '/';

  const handleLogin = async () => {
    loading.onTrue();
    const loginResponse = await login();
    console.log('loginResponse', loginResponse);
    if (loginResponse.success) {
      enqueueSnackbar('Login success', { autoHideDuration: 500 });
    } else {
      enqueueSnackbar(loginResponse?.error ?? 'You must login to continue', { variant: 'error' });
      setTimeout(() => {
        handleLogin();
      }, 1000); // Delay of 1 second
    }
    loading.onFalse();
  };

  const handleBack = () => {
    // Check if current path is booking detail or cabcheckout
    const isBookingDetail = pathname.startsWith('/booking/');
    const isCabCheckout = pathname === '/cabcheckout';

    if (isBookingDetail || isCabCheckout) {
      window.history.back();
    } else {
      // For all other pages, navigate to home
      router.replace('/');
    }
  };

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Grid container alignItems="center">
            <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              {!isHome && (
                <IconButton color="inherit" onClick={handleBack} sx={{ mx: -1 }}>
                  <Iconify icon="eva:arrow-ios-back-fill" />
                </IconButton>
              )}
            </Grid>

            <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Logo />
            </Grid>

            <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!authenticated ? (
                <LoadingButton
                  loading={loading.value}
                  variant="text"
                  size="small"
                  color="primary"
                  onClick={handleLogin}
                >
                  LOGIN
                </LoadingButton>
              ) : (
                <AccountPopover />
              )}
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
      {/* <Typography color="#00000010" variant="caption">{cookies.puid}<br />{cookies.grantToken}</Typography> */}

      {!isHome && <HeaderShadow />}
    </AppBar>
  );
}
