import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import Box from '@mui/material/Box';
import { useRouter } from 'src/routes/hooks';
// routes

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const router = useRouter();
  return (
    <Box
      component="img"
      src="/logo/logo_single.svg"
      sx={{ width: 78, cursor: 'pointer', ...sx }}
      onClick={disabledLink ? () => null : () => router.popAllThenGoToHome()}
      {...other}
    />
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
