import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();

  const router = useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      reload: () => window.location.reload(),
      popAllThenGoToHome: () => {
        // First clear history by replacing current state
        navigate('/', { replace: true });
      },
      popAllThenGoToHomeThenPush: (href) => {
        // First clear history by replacing current state
        navigate('/', { replace: true });
        // Then push the new route
        if (href) {
          navigate(href);
        }
      },
      push: (href) => navigate(href),
      replace: (href) => navigate(href, { replace: true }),
    }),
    [navigate]
  );

  return router;
}
