import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const CabsContext = createContext({});

export const useCabsContext = () => {
  const context = useContext(CabsContext);

  if (!context) throw new Error('useCabsContext must be use inside CabsProvider');

  return context;
};
