import axios from 'axios';
// config
import { PHONEPE_BACKEND_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: PHONEPE_BACKEND_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Network error')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/client/user',
  },
  places: {
    city: '/public/entity/places/city',
    airport: '/public/entity/places/airport',
    map: '/public/map/places',
    distance: '/public/map/distance',
  },
  cabs: {
    packages: '/public/cabs/packages',
    booking: '/client/booking',
    bookingDetail: (id) => `/client/booking/${id}`,
  },
  whatsapp: {
    test: '/client/checkWhatsappNumber',
  },
};
